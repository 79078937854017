
export function getClosest( elem: HTMLElement, selector: string ): any {

    // Variables
    let firstChar = selector.charAt(0);
    let supports = 'classList' in document.documentElement;
    let attribute, value;

    // If selector is a data attribute, split attribute from value
    if ( firstChar === '[' ) {
        selector = selector.substr( 1, selector.length - 2 );
        attribute = selector.split( '=' );

        if ( attribute.length > 1 ) {
            value = true;
            attribute[1] = attribute[1].replace( /"/g, '' ).replace( /'/g, '' );
        }
    }

    // Get closest match
    for ( ; elem && elem !== document.body && elem.nodeType === 1; elem = <HTMLElement>elem.parentNode ) {

        // If selector is a class
        if ( firstChar === '.' ) {
            if ( supports ) {
                if ( elem.classList.contains( selector.substr(1) ) ) {
                    return elem;
                }
            } else {
                if ( new RegExp('(^|\\s)' + selector.substr(1) + '(\\s|$)').test( elem.className ) ) {
                    return elem;
                }
            }
        }

        // If selector is an ID
        if ( firstChar === '#' ) {
            if ( elem.id === selector.substr(1) ) {
                return elem;
            }
        }

        // If selector is a data attribute
        if ( firstChar === '[' ) {
            if ( elem.hasAttribute( attribute[0] ) ) {
                if ( value ) {
                    if ( elem.getAttribute( attribute[0] ) === attribute[1] ) {
                        return elem;
                    }
                } else {
                    return elem;
                }
            }
        }

        // If selector is a tag
        if ( elem.tagName.toLowerCase() === selector ) {
            return elem;
        }

    }

    return null;

}

export const flatpickr_lv_i10n = {
    firstDayOfWeek: 1,
    weekdays: {
	    shorthand: ['Sv', 'P', 'O', 'T', 'C', 'Pk', 'S'],
	    longhand: ['Svētdiena', 'Pirmdiena', 'Otrdiena', 'Trešdiena', 'Ceturtdiena', 'Piektdiena', 'Sestdiena']
    },
    months: {
        shorthand: ['Jan', 'Fab', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        longhand: ['Janvāris', 'Februāris', 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris']
    }
};


export interface OptionsObject {
    dateFormat?: string;
    enableTime?: boolean;
    time_24hr?: boolean;
    triggerChange?: boolean;
    onChange?(selectedDateObj: HTMLElement, value: string, self: flatpickr): void;
}

declare class flatpickr {
    constructor(selector: HTMLElement, options?: OptionsObject);
    config: OptionsObject;
    static  init: {
        prototype: {
            l10n: {
                firstDayOfWeek: number;
            }
        }
    };
}
