import Choices from "../../tools/node_modules/choices.js";
import { Latvian } from "../../tools/node_modules/flatpickr/dist/l10n/lv";
import {getClosest} from "./components/utils";

window.addEventListener("DOMContentLoaded", () => {
  console.log("Loaded Scripts");
});

// Light switcher
const lightSwitches = document.querySelectorAll<HTMLInputElement>('.light-switch');
if (lightSwitches.length > 0) {
  lightSwitches.forEach((lightSwitch, i) => {
    if (localStorage.getItem('dark-mode') === 'true') {
      // eslint-disable-next-line no-param-reassign
      lightSwitch.checked = true;
    }
    lightSwitch.addEventListener('change', () => {
      const { checked } = lightSwitch;
      lightSwitches.forEach((el, n) => {
        if (n !== i) {
          // eslint-disable-next-line no-param-reassign
          (el as HTMLInputElement).checked = checked;
        }
      });
      document.documentElement.classList.add('[&_*]:!transition-none');
      if (lightSwitch.checked) {
        document.documentElement.classList.add('dark');
        // @ts-ignore
          (document.querySelector('html') as HTMLElement).style.colorScheme = 'dark';
        localStorage.setItem('dark-mode', 'true');
        document.dispatchEvent(new CustomEvent('darkMode', { detail: { mode: 'on' } }));
      } else {
        document.documentElement.classList.remove('dark');
        // @ts-ignore
        (document.querySelector('html') as HTMLElement).style.colorScheme = 'light';
        localStorage.setItem('dark-mode', 'false');
        document.dispatchEvent(new CustomEvent('darkMode', { detail: { mode: 'off' } }));
      }
      setTimeout(() => {
        document.documentElement.classList.remove('[&_*]:!transition-none');
      }, 1);
    });
  });
}



export const dateOptions = {
    dateFormat: 'Y-m-d',
    time_24hr: true,
    locale: Latvian
};
export const dateTimeOptions = {
    ...dateOptions,
    enableTime: true,
    dateFormat: 'Y-m-d H:i',
};

class FormUI {
    [key: string]: Function;

    constructor() {
        // this.datetime();
        // this.date();
        // this.add_formsets();
        // this.disable_enter();
        this.choices();
        this.choices_invalid();
    }



    // disable_enter() {
    //     window.addEventListener('keydown',function(event) {
    //         let element = <HTMLElement>event.target;
    //
    //         let f = getClosest(element, "form");
    //         if (f && !f.classList.contains('enter-submit')) {
    //             if(element.tagName == 'INPUT') {
    //                 if(event.keyCode == 13) {
    //                     event.stopPropagation();
    //                     event.preventDefault();
    //                     return false;
    //                 }
    //             }
    //         }
    //     }, true);
    // }

    // datetime() {
    //     let dt = document.body.querySelectorAll('.datetimeinput');
    //     for (let _ii = 0; _ii < dt.length; _ii++) {
    //         let element = <HTMLInputElement>dt[_ii];
    //         flatpickr(element, {...dateTimeOptions});
    //     }
    // }
    // date() {
    //     let dt = document.body.querySelectorAll('.dateinput');
    //     for (let _ii = 0; _ii < dt.length; _ii++) {
    //         let element = <HTMLInputElement>dt[_ii];
    //         flatpickr(element, {...dateOptions});
    //     }
    // }

    choices() {
        let dt = document.body.querySelectorAll('select.choicesinput');
        for (let _ii = 0; _ii < dt.length; _ii++) {
            let element = <HTMLInputElement>dt[_ii];

            new Choices(element);
        }
    }

    choices_invalid() {
        let dt = document.body.querySelectorAll('select.is-invalid');
        for (let _ii = 0; _ii < dt.length; _ii++) {
            let element = <HTMLInputElement>dt[_ii];
            let choices = getClosest(element, '.choices');
            choices.classList.add('is-invalid');
        }
    }

    // call_formset_function(row: HTMLElement, container: HTMLElement, type: string) {
    //     var function_name: string = "";
    //     var classes = container.classList;
    //     for (var _i = 0; _i < classes.length; _i++) {
    //       if (classes[_i].indexOf('_inline_class') !== -1) {
    //         function_name = classes[_i];
    //       }
    //     }
    //     function_name += '_' + type;
    //     if (window.console)
    //       console.log(function_name);
    //
    //     if (typeof this[function_name] === 'function') {
    //       return this[function_name](row);
    //     }
    // }

    // add_formsets() {
    //     var formsets = document.querySelectorAll(".django-inline-form");
    //     for (var _i = 0; _i < formsets.length; _i++) {
    //         var formset = <HTMLElement>formsets[_i];
    //         var prefix = formset.dataset['prefix'];
    //         var container = <HTMLDivElement>formset.querySelector(".formset_container");
    //         formset.formset({
    //             container: container,
    //             prefix: prefix,
    //             addCssSelector: '.add-row',
    //             deleteButtonSelector: '.delete-row',
    //             formTemplateSelector: ".template",
    //             added: (row: HTMLElement) => {
    //                 this.call_formset_function(row, formset, 'added');
    //             },
    //             removed: (row: HTMLElement) => {
    //                 this.call_formset_function(row, formset, 'removed');
    //             }
    //         });
    //     }
    // }

}


if (document.body.querySelector('form')) {
    new FormUI();
}
