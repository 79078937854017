import Choices from "../../tools/node_modules/choices.js";

export {};


let initApp = () => {
  // Pass single element
  const element = document.querySelector('select.choices-js');
  if (element) {
      // @ts-ignore
      const choices = new Choices(element, {"shouldSort": false});
  }

    if (document.querySelector("#login-form .timeleft")) {
        let countdownNum = 40;

        let incTimer = function() {
            setTimeout(function () {
                 let timeleft = document.querySelector('#login-form .timeleft');
                if (timeleft) {
                    if (countdownNum != 0) {
                        countdownNum--;
                        timeleft.innerHTML = countdownNum.toString();
                        incTimer();
                    } else {
                        // (<HTMLButtonElement>document.querySelector('#login-form button.resend-btn'))
                        let btn = <HTMLButtonElement>document.querySelector('#login-form button.resend-btn');
                        if (btn)
                            btn.disabled = false;
                        timeleft.innerHTML = '';
                    }
                }
            }, 1000);
        };
        incTimer();
    }

}

initApp();
